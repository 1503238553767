import React from "react";
import { Link } from "react-router-dom";
import FreeDelivery from "./components/FreeDelivery";
import HowToDeliver from "./components/HowToDeliver";
import PageHeader from "./components/PageHeader";
import apple from "./img/apple.png";
import img1 from "./img/iPhone-12.png";
import img2 from "./img/feature2.png";
import google from "./img/google.png";
import point_shape from "./img/point-shape.png";
const Delivery = () => {
	return (
		<>
			<PageHeader />
			<div className="overflow-hidden">
				<FreeDelivery />
				<HowToDeliver />
			</div>
			{/* Delivery Feature */}
			<section className="delivery-feature">
				<img className="point_shape" src={point_shape} alt="" />
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-lg-6 text-center">
							{/* <img
								src={img1}
								alt=""
								className="mobile-full mobile-full-2"
							/> */}
							<img src={img1} alt="" className="mobile-full" />
						</div>
						<div className="col-lg-6">
							<h4 style={{ color: "#9D8140", marginTop: "34px" }}>
								No Hidden Fees !
							</h4>
							<div className="hidden-charge-content">
								As our partner,{" "}
								<strong>your restaurant does not get charged</strong> to
								be on our platform and delivery fees are on our charge.
							</div>
						</div>
					</div>
					<div className="row align-items-center flex-wrap-reverse g-4 justify-content-end pt-5 pt-lg-0">
						<div className="col-lg-5">
							<div
								style={{
									maxWidth: "343px",
									marginInline: "auto",
								}}
							>
								<h4
									style={{
										color: "#9D8140",
										marginTop: "34px",
									}}
								>
									Easy to get started !
								</h4>
								<div>
									<ul className="ps-3 hidden-charge-content">
										<li>
											<strong>Select</strong> your virtual brand
										</li>
										<li>
											<strong>Receive</strong> your first batch
										</li>
										<li>
											<strong>Access</strong> your dashboard
										</li>
										<li>
											<strong>Go live</strong> and start taking
											orders
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-xl-4">
							<img src={img2} alt="" className="mobile-full mw-100" />
						</div>
					</div>
					<div
						className="btn-wrapper"
						style={{ maxWidth: "906px", margin: "35px auto 0" }}
					>
						<Link to="https://play.google.com/store/apps/details?id=com.heyjag.merchants">
							<img src={google} alt="" />
						</Link>
						<Link to="https://apps.apple.com/us/app/hey-jag-merchant/id6739029277" className="me-auto">
							<img src={apple} alt="" />
						</Link>
						<Link
							to="https://www.heyjag.com"
							target="_blank"
							className="cmn-btn"
						>
							<span>LEARN MORE ABOUT HEY!JAG</span>
						</Link>
					</div>
				</div>
			</section>
			{/* Delivery Feature */}
		</>
	);
};

export default Delivery;
