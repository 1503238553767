import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { ClearIcon, Hamburger, MessageIcon, PhoneIcon } from "../IconTheme";
const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	return (
		<header>
			<div className="header-top bg--gradient">
				<div className="container">
					<div
						className="d-flex flex-wrap justify-content-evenly justify-content-sm-between"
						style={{ gap: "5px" }}
					>
						<span className="text-title font-semibold">
							NO INVESTMENT TO START
						</span>
						<Link
							to="mailto:contact@simplevirtualkitchen.com"
							className="text-white"
						>
							contact@simplevirtualkitchen.com
						</Link>
					</div>
				</div>
			</div>
			<div className="header-bottom bg--title">
				<div className="container">
					<div className="header-wrapper">
						<Link to="/" className="logo">
							<img src={logo} alt="" />
						</Link>
						<div className="d-lg-none">
							<span
								type="button"
								onClick={() => setIsMenuOpen(!isMenuOpen)}
							>
								{isMenuOpen ? <ClearIcon /> : <Hamburger />}
							</span>
						</div>
						<div className={`header-menu ${isMenuOpen ? "active" : ""}`}>
							<ul className="menu">
								<li>
									<NavLink
										to="/"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/brands"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Brands
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/delivery"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Delivery
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/blog"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										BLOG
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/about"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										About
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/contact"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Contact
									</NavLink>
								</li>
							</ul>
							<div className="right-buttons my-auto my-lg-0 text-center">
								<RightIcons />
							</div>
							<div
								className="d-lg-none"
								style={{
									position: "absolute",
									top: "15px",
									right: "20px",
								}}
							>
								<span
									type="button"
									onClick={() => setIsMenuOpen(!isMenuOpen)}
								>
									{isMenuOpen ? <ClearIcon /> : <Hamburger />}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export const RightIcons = () => {
	return (
		<>
			<Link className="cmn-btn" to="tel:(561) 345 - 9629">
				<span>(561) 319 - 0974</span>
			</Link>
			<Link to="tel:(561) 319 - 0974" className="header-icon">
				<PhoneIcon />
			</Link>
			<Link to="/contact" className="header-icon">
				<MessageIcon />
			</Link>
		</>
	);
};

export default Header;
