import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle";
import { Message, Phone, Pin } from "../../../../IconTheme";
import img from "../img/contact-bg.png";
import emailjs from '@emailjs/browser';
import { toast } from "react-hot-toast";


const ContactSection = () => {
	const form = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		
		emailjs
			.sendForm(
				 'service_t8cc8t8',
            	'template_4jjxhby',
				form.current,
				process.env.REACT_APP_EMAILJS_PUBLIC_API
			)
			.then(
				(result) => {
					if (result.status === 200) {
						toast.success(
							"Thank you for reaching out! We've received your message and will get back to you soon.",
							{
								id: 1,
							}
						);
						e.target.reset();
					}
				},
				(error) => {
					toast.error("Something went wrong! Please try again.", {
						id: 1,
					});
				}
			);
	};

	return (
		<>
			<section
				className="contact-section"
				style={{
					background: `url(${img}) no-repeat center center / cover`,
				}}
			>
				<div className="container">
					<div className="mx-auto" style={{ maxWidth: "675px" }}>
						<SectionTitle
							centerAlign
							shapeColor
							title="Send Us A Message"
							subtext="Want to learn more about our virtual brands or need further informations on how it works? Leave us a message and we will get back to you as soon as possible."
						/>
					</div>
					<div style={{ maxWidth: "771px" }} className="mx-auto">
						<form ref={form} onSubmit={handleSubmit}>
							<div className="row g-3">
								<div className="col-sm-12">
									<label className="form-label">Name</label>
									<input
										name="Name"
										type="text"
										className="form-control"
										required
									/>
								</div>
								<div className="col-sm-6">
									<label className="form-label">Email Address</label>
									<input
										name="Email Address"
										type="text"
										className="form-control"
										required
									/>
								</div>
								<div className="col-sm-6">
									<label className="form-label">Phone Number</label>
									<input
										name="Phone Number"
										type="text"
										className="form-control"
										required
									/>
								</div>
								<div className="col-sm-12">
									<label className="form-label">Message</label>
									<textarea
										required
										name="Message"
										className="form-control"
									></textarea>
								</div>
								<div className="col-sm-12 mt-4 pt-3">
									<button type="submit" className="cmn-btn w-100">
										<span>SUBMIT</span>
									</button>
								</div>
							</div>
						</form>
					</div>
					<div style={{ maxWidth: "1160px", margin: "0 auto" }}>
						<div className="row g-3 justify-content-center mt-4">
							<div className="col-sm-6 col-md-4">
								<div className="contact-card">
									<div className="icon">
										<Phone />
									</div>
									<Link to="tel:(561) 345 - 9629">
										(561) 319 - 0974
									</Link>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="contact-card">
									<div className="icon">
										<Pin />
									</div>
									<div>350 N Federal Hwy</div>
									<div>Boynton Beach FL, 33435</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-4">
								<div className="contact-card">
									<div className="icon">
										<Message />
									</div>
									<Link to="tel:contact@simplevirtualkitchen.com">
										contact@simplevirtualkitchen.com
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ContactSection;
