import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import SectionTitle from "../../../components/SectionTitle";
import img from "../img/subscribe-bg.png";

const Subscribe = () => {
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        // Show loading toast
        toast.loading("Sending...", { id: "subscribe-toast" });

        // Send email via EmailJS
        emailjs
            .sendForm(
                "service_t8cc8t8", // Replace with your EmailJS Service ID
                "template_4jjxhby", // Replace with your EmailJS Template ID
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_API // Ensure this is defined in .env
            )
            .then(
                (result) => {
                    if (result.status === 200) {
                        // Show success toast and reset the form
                        toast.success("Thank you for subscribing!", {
                            id: "subscribe-toast",
                        });
                        form.current.reset(); // Clear form inputs
                    }
                },
                (error) => {
                    // Show error toast
                    toast.error("Something went wrong! Please try again.", {
                        id: "subscribe-toast",
                    });
                }
            );
    };

    return (
        <div
            className="subscribe-section"
            style={{
                background: `url(${img}) no-repeat center center / cover`,
            }}
        >
            <div className="container">
                <div className="subscribe-content">
                    <div className="content">
                        <SectionTitle
                            centerAlign
                            shapeColor
                            title="Follow us"
                            className="mb-3"
                        />
                        <p className="text-center mb-4 pb-2">
                            Are you interested in our activities? <br /> Subscribe
                            to our newsletter to not miss anything!
                        </p>
                        <form ref={form} onSubmit={handleSubmit}>
                            <label className="form-label">Email Address</label>
                            <input
                                type="email" // Ensure proper validation
                                name="Email" // Match this with EmailJS template field
                                className="form-control mb-4 mb-md-5"
                                placeholder="Enter your email"
                                required
                            />
                            <button
                                className="cmn-btn w-100 text-uppercase"
                                type="submit"
                            >
                                <span>Subscribe</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;

