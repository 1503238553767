import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import {
	Instagram,
	LinkedinIn,
	MessageIcon2,
	PhoneIcon2,
	Twitter,
} from "../IconTheme";
const Footer = () => {
	return (
		<footer>
			<div className="footer-top bg--title ">
				<div className="container">
					<div className="footer-wrapper align-items-center">
						<div className="footer-widget widget-about">
							<Link to="/" className="logo mb-3">
								<img src={logo} alt="" />
							</Link>
							<p>
								Add a virtual brand to your restaurant based on
								only-delivery model to increase your profit without
								increasing your operational costs.
							</p>
							<div className="footer-icons">
								<Link to="Tel:(561) 345-9629" className="header-icon">
									<PhoneIcon2 />
								</Link>
								<Link to="/contact" className="header-icon">
									<MessageIcon2 />
								</Link>
							</div>
						</div>
						<div className="footer-widget widget-link">
							<h5 className="widget-title">About Us</h5>
							<ul>
								<li>
									<Link to="https://heyjag.com/">Hey! JAG</Link>
								</li>
								<li>
									<Link to="https://heyjag.com/connect/">JAG Food</Link>
								</li>
								<li>
									<Link to="https://heyjag.io/">JAG Token</Link>
								</li>
								<li>
									<Link to="https://jagheroes.com/">JAG Heroes</Link>
								</li>
								<li>
									<Link to="https://www.heyjag.io/whitepaper.pdf">Whitepaper</Link>
								</li>
								<li>
									<Link to="https://apps.apple.com/us/app/hey-jag-merchant/id6739029277">Hey! JAG Merchant Apple</Link>
								</li>
								<li>
									<Link to="https://play.google.com/store/apps/details?id=com.heyjag.merchants">Hey! JAG Merchant Google</Link>
								</li>
								
								
								
							</ul>
						</div>
						<div className="footer-widget widget-link">
							<h5 className="widget-title">Site Links</h5>
							<ul>
								<li>
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/brands">Brands</Link>
								</li>
								<li>
									<Link to="/delivery">Delivery</Link>
								</li>
								<li>
									<Link to="/blog">Blog</Link>
								</li>
								<li>
									<Link to="/about">About</Link>
								</li>
								<li>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>
						<div className="footer-widget widget-link">
							<h5 className="widget-title">Virtual Brands</h5>
							<ul>
								<li>
									<Link to="https://justchickenwings.com/">Just Chicken Wings</Link>
								</li>
								<li>
									<Link to="/brands">The Imperial Bamboo</Link>
								</li>
								<li>
									<Link to="/brands">Burger French Fries</Link>
								</li>
								<li>
									<Link to="/brands">Crazy Tasty Chicken</Link>
								</li>
								<li>
									<Link to="/brands">Subs Zone </Link>
								</li>
								<li>
									<Link to="https://www.nypdpizza.io/">NYPD Pizza</Link>
								</li>
							</ul>
						</div>
						
						<div className="footer-widget widget-contact">
							<h5 className="widget-title">Contact Information</h5>
							<ul>
								<li>350 N Federal Hwy, Boynton Beach FL, 33435</li>
								<li>contact@simplevirtualkitchen.com</li>
								<li>(561) 319-0974</li>
							</ul>
							<div className="footer-icons">
								<Link
									to="https://www.linkedin.com/company/simple-virtual-kitchen/"
									className="header-icon"
									target="_blank"
								>
									<LinkedinIn />
								</Link>
								<Link
									to="https://www.instagram.com/simplevirtualkitchen/"
									className="header-icon"
									target="_blank"
								>
									<Instagram />
								</Link>
								<Link
									to="https://twitter.com/SimpleVirtualK"
									target="_blank"
									className="header-icon"
								>
									<Twitter />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="footer-bottom bg--gradient font-bold"
				style={{ fontSize: "13px", lineHeight: "20px", padding: "10px 0" }}
			>
				<div className="container">
					&copy; 2025 All Rights Reserved | Simple Virtual Kitchen
				</div>
			</div>
		</footer>
	);
};

export default Footer;
