import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container w-full px-4 py-8 md:px-12 lg:px-24">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-6">Last updated: January 26, 2025</p>
      <p>
        At <strong>Simple Virtual Kitchen (SVK)</strong>, part of <strong>Just Wings Inc.</strong>, we respect your privacy and are committed to protecting the information you share with us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services or visit our website.
      </p>
      <p className="mt-4">
        Please note that all delivery services provided through SVK are managed by our trusted partner <strong>Hey Jag Inc.</strong>, ensuring secure and seamless delivery operations.
      </p>
      <hr className="my-6" />

      <h4 className="text-2xl font-semibold mb-4">1. Information We Collect</h4>
      <p className="mb-4">
        We collect both personal and non-personal information, which may include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Personal Information</strong>: Name, email address, phone number, business details, and payment information (processed securely by third-party payment platforms).
        </li>
        <li>
          <strong>Non-Personal Information</strong>: Device information (e.g., browser type, IP address) and usage statistics to improve our services.
        </li>
      </ul>

      <h4 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Facilitate and provide virtual kitchen services.</li>
        <li>Customize and improve your experience.</li>
        <li>Ensure secure delivery services through Hey Jag Inc.</li>
        <li>Comply with legal obligations and enforce Terms of Service.</li>
      </ul>

      <h4 className="text-2xl font-semibold mb-4">3. Sharing of Information</h4>
      <p className="mb-4">
        Your information may only be shared with trusted third-party partners (e.g., payment processors, delivery services like Hey Jag Inc.) or as required by law. We do not sell or rent your personal information.
      </p>

      <h4 className="text-2xl font-semibold mb-4">4. Data Security</h4>
      <p className="mb-4">
        We implement robust security measures to protect your data. However, no system is completely secure. Use our services at your own risk.
      </p>

      <h4 className="text-2xl font-semibold mb-4">5. Your Rights</h4>
      <p className="mb-4">
        Depending on your jurisdiction, you may have rights such as data access, correction, deletion, and revoking consent. Contact us to exercise these rights.
      </p>

      <h4 className="text-2xl font-semibold mb-4">6. Contact Us</h4>
      <p>
        If you have any questions or concerns, please contact us:
      </p>
      <p className="mt-4">
        <strong>Simple Virtual Kitchen</strong> (Part of Just Wings Inc.) <br />
        350 N Federal Hwy, Boynton Beach FL, 33435 <br />
        <strong>Email</strong>: contact@simplevirtualkitchen.com <br />
        <strong>Phone</strong>: (561) 319-0974
      </p>
    </div>
  );
};

export default PrivacyPolicy;



