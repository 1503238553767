/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Maps = () => {
	return (
		<>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7139.400541937664!2d-80.0601933874651!3d26.529762576781028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8df263ff3c863%3A0xcb6f56a3976ae3df!2s350%20N%20Federal%20Hwy%2C%20Boynton%20Beach%2C%20FL%2033435!5e0!3m2!1sen!2sus!4v1737596282389!5m2!1sen!2sus"
				height="387"
				style={{ width: "100%", display: "block" }}
				frameBorder="0"
			></iframe>
		</>
	);
};

export default Maps;
