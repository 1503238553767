import React, { useRef } from "react";
import { toast } from "react-hot-toast";
import bg from "../img/banner-bg.png";

const Banner = () => {
  return (
    <>
      <section
        className="banner-section"
        style={{ background: `url(${bg}) no-repeat center center / cover` }}
      >
        <div className="container">
          <div className="banner-wrapper">
            <div className="banner-content">
              <span className="line"></span>
              <h1 className="title">
                Add A Virtual Brand To Your Restaurant Kitchen
              </h1>
              <p>
                Increase your restaurant profit without increasing your
                costs.
              </p>

              {/* Updated Button with Google Calendar Link */}
              <a
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3bx6kbQqtu-wVjgCL2H26Z16mSbWqgSAt5J-bVWbFdx6o_jy0Knt21nnGAvFeQfaJpx7LtZld7?gv=true"
                target="_blank"
                rel="noopener noreferrer"
                className="cmn-btn"
              >
                <span>Book a Free Consultation</span>
              </a>
            </div>

            <div className="banner-form bg--title">
              <div className="text-center">
                <h5 className="text--base">We want to hear from you</h5>
                <p>
                  Send us your details and we’ll get back to you to schedule a
                  time to talk.
                </p>
              </div>
              {/* Optionally keep your form below */}
              <form>
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    required
                    name="Name"
                    className="form-control-2"
                    type="text"
                    id="name"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    required
                    name="Email"
                    className="form-control-2"
                    type="text"
                    id="email"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    required
                    name="Phone"
                    className="form-control-2"
                    type="text"
                    id="phone"
                  />
                </div>
                <div className="text-center pt-3">
                  <button type="submit" className="cmn-btn">
                    <span>send</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

