import React, { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import BlogSingle from "./pages/blog/BlogSingle";
import Brands from "./pages/brands/Brands";
import Delivery from "./pages/delivery/Delivery";
import Contact from "./pages/home/contact/Contact";
import Home from "./pages/home/Home";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfService from "./pages/terms-of-service/TermsOfService";

function App() {
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useLayoutEffect(() => {
			document.documentElement.scrollTo(0, 0);
		}, [location.pathname]);
		return children;
	};
	return (
		<BrowserRouter>
			<Wrapper>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/brands" element={<Brands />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/blog/:id" element={<BlogSingle />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/delivery" element={<Delivery />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/terms-of-service" element={<TermsOfService />} />
				</Routes>
				<Footer />
			</Wrapper>
		</BrowserRouter>
	);
}

export default App;
