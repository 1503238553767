import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container w-full px-4 py-8 md:px-12 lg:px-24">
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p className="text-sm text-gray-600 mb-6">Last updated: January 26, 2025</p>
      <p>
        Welcome to <strong>Simple Virtual Kitchen (SVK)</strong>, part of <strong>Just Wings Inc.</strong>. By using our services and website ("Services"), you agree to abide by these Terms of Service ("Terms"). Please read these Terms carefully. If you do not agree, discontinue using our Services.
      </p>
      <p className="mt-4">
        Please note that all delivery services provided as part of SVK are managed by <strong>Hey Jag Inc.</strong>.
      </p>
      <hr className="my-6" />

      <h4 className="text-2xl font-semibold mb-4">1. Use of Services</h4>
      <p className="mb-4">
        You may use our Services only for lawful purposes. Prohibited activities include violating laws, misusing our platform, and interfering with operations. Unauthorized activities may result in termination.
      </p>

      <h4 className="text-2xl font-semibold mb-4">2. Account Responsibilities</h4>
      <p className="mb-4">
        You are responsible for keeping your login credentials secure and for all activities under your account. Notify us immediately of unauthorized access.
      </p>

      <h4 className="text-2xl font-semibold mb-4">3. Fees and Payments</h4>
      <p className="mb-4">
        All fees must be paid via trusted third-party processors (e.g., Stripe). Failure to pay may result in account suspension or termination.
      </p>

      <h4 className="text-2xl font-semibold mb-4">4. Delivery Services</h4>
      <p className="mb-4">
        All deliveries are managed by <strong>Hey Jag Inc.</strong>. By using our Services, you agree to comply with Hey Jag Inc.’s terms for delivery operations.
      </p>

      <h4 className="text-2xl font-semibold mb-4">5. Intellectual Property</h4>
      <p className="mb-4">
        All content, trademarks, and materials are owned by Simple Virtual Kitchen or its licensors. Unauthorized use of our intellectual property is prohibited.
      </p>

      <h4 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h4>
      <p className="mb-4">
        We are not liable for indirect or consequential damages. Our liability is limited to fees paid for the Services in the preceding 12 months.
      </p>

      <h4 className="text-2xl font-semibold mb-4">7. Termination</h4>
      <p className="mb-4">
        We may suspend or terminate your account for violating these Terms. Upon termination, discontinue using the Services immediately.
      </p>

      <h4 className="text-2xl font-semibold mb-4">8. Governing Law</h4>
      <p className="mb-4">
        These Terms are governed by the laws of the State of Florida. Disputes shall be resolved exclusively in Florida courts.
      </p>

      <h4 className="text-2xl font-semibold mb-4">9. Contact Us</h4>
      <p>
        For any questions or concerns regarding these Terms, please contact us:
      </p>
      <p className="mt-4">
        <strong>Simple Virtual Kitchen</strong> (Part of Just Wings Inc.) <br />
        350 N Federal Hwy, Boynton Beach FL, 33435 <br />
        <strong>Email</strong>: contact@simplevirtualkitchen.com <br />
        <strong>Phone</strong>: (561) 319-0974
      </p>
    </div>
  );
};

export default TermsOfService;


